import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Button, CircularProgress, alpha, keyframes } from '@mui/material';

import { Colors } from '../../../design/theme';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import {
  generateCustomAffirmation,
  postAffirmation
} from '../../../redux/affirmation/affirmation.action';
import { affirmationSelector } from '../../../redux/affirmation/affirmation.selector';
import { audioProfilesSelector } from '../../../redux/audio-profiles/audio-profiles.selector';
import { chatSelector } from '../../../redux/chat/chat.selector';
import { resetSuggestedAction } from '../../../redux/chat/chat.slice';
import {
  createMeditation,
  generateMeditation
} from '../../../redux/meditations/meditation.action';
import { medidationSelector } from '../../../redux/meditations/meditation.selector';
import { cleanMeditation } from '../../../redux/meditations/meditation.slice';
import { CreateMeditation } from '../../../redux/meditations/types/meditation.entity';
import { CreateVisualization } from '../../../redux/visualization/types/visualizations';
import {
  createVisualization,
  generateVisualization
} from '../../../redux/visualization/visualization.action';
import { visualizationSelector } from '../../../redux/visualization/visualization.selector';
import { cleanVisualization } from '../../../redux/visualization/visualization.slice';
import RoutesEnum from '../../../types/routes.enum';
import { analyticsEvents, logAnalyticsEvent } from '../../../utils/analytics';
import {
  setActualStep,
  setCustomFlow,
  setCustomOutcome,
  setCustomQualities
} from '../../new-affirmation/redux/ui/ui.slice';
import {
  AffirmationParameters,
  MeditationParameters,
  SuggestedActionPayload,
  VisualizationParameters
} from '../types/suggested-action-payload.interface';
import {
  isAffirmationParameters,
  isMeditationParameters,
  isVisualizationParameters
} from '../utils/type-guards';

const pulseEffect = keyframes`
    0% { background-color: ${Colors.grey}; border: none; }
    50% { background-color: ${Colors.lightGrey}; border: none; }
    100% { background-color: ${Colors.grey}; border: none; }
  `;

const SuggestedAction = () => {
  const { suggestedActions, incomingSuggestions } = useSelector(chatSelector);
  const isLargeScreen = useIsLargeScreen();
  const { createdAffirmation, selectedTemplate } =
    useAppSelector(affirmationSelector);
  const { audioDuration } = useAppSelector(audioProfilesSelector);
  const { createdMeditation } = useAppSelector(medidationSelector);
  const { createdVisualization } = useAppSelector(visualizationSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState({
    affirmation: false,
    meditation: false,
    visualization: false
  });

  const handleNewAffirmation = async (action: AffirmationParameters) => {
    setLoadingState((prev) => ({ ...prev, affirmation: true }));
    dispatch(setCustomOutcome(action.outcome));
    dispatch(
      setCustomQualities([
        {
          quality: action.qualities[0],
          traits: action.qualities
        }
      ])
    );
    dispatch(setCustomFlow(true));
    dispatch(setActualStep(6));

    await dispatch(
      generateCustomAffirmation({
        customAffirmation: true,
        customParameters: {
          developmentArea: 'Interpersonal',
          outcome: action.outcome,
          qualities: [
            {
              quality: action.qualities[0],
              traits: [{ trait: action.qualities[0] }]
            }
          ],
          chakras: action.chakras ? action.chakras : undefined
        }
      })
    );

    if (
      createdAffirmation &&
      createdAffirmation.affirmationId &&
      !selectedTemplate
    ) {
      await dispatch(
        postAffirmation({
          affirmationId: createdAffirmation.affirmationId,
          duration: audioDuration,
          customAffirmation: true
        })
      );
      dispatch(setCustomFlow(false));
    }

    navigate(RoutesEnum.NEW_AFFIRMATION);
  };

  useEffect(() => {
    dispatch(cleanMeditation());
    dispatch(cleanVisualization());
    logAnalyticsEvent(analyticsEvents.avatar.suggested_action_display);

    return () => {
      dispatch(resetSuggestedAction());
    };
  }, []);

  const handleNewMeditation = async (action: MeditationParameters) => {
    setLoadingState((prev) => ({ ...prev, meditation: true }));
    const transformedValues: CreateMeditation = {
      outcome: {
        outcome: action.outcome
      },
      quality: {
        quality: action.quality
      },
      goal: {
        goal: action.goal
      },
      attribute: {
        attribute: action.attribute
      }
    };
    await dispatch(createMeditation(transformedValues));
  };

  const handleNewVisualization = async (action: VisualizationParameters) => {
    setLoadingState((prev) => ({ ...prev, visualization: true }));
    const transformedValues: CreateVisualization = {
      quality: {
        quality: action.quality
      },
      emotion: {
        emotion: action.emotion
      },
      situation: {
        situation: action.situation
      },
      outcome: {
        outcome: action.outcome
      }
    };
    await dispatch(createVisualization(transformedValues));
  };

  useEffect(() => {
    if (createdMeditation && suggestedActions) {
      dispatch(generateMeditation({ meditationId: createdMeditation.id }))
        .unwrap()
        .then(() => {
          navigate(`${RoutesEnum.EDIT_MEDITATION}${createdMeditation.id}`);
        });
    }

    if (createdVisualization && suggestedActions) {
      dispatch(
        generateVisualization({ visualizationId: createdVisualization.id })
      )
        .unwrap()
        .then(() => {
          navigate(
            `${RoutesEnum.EDIT_VISUALIZATION}${createdVisualization.id}`
          );
        });
    }
  }, [createdMeditation, createdVisualization]);

  const handleSuggestionClick = async (
    action: SuggestedActionPayload['parameters']
  ) => {
    logAnalyticsEvent(analyticsEvents.avatar.select_suggested_action);

    if (isAffirmationParameters(action))
      return await handleNewAffirmation(action);
    if (isMeditationParameters(action))
      return await handleNewMeditation(action);
    if (isVisualizationParameters(action))
      return await handleNewVisualization(action);
  };

  if (incomingSuggestions) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '-0.5rem'
        }}>
        <Box
          sx={{
            height: '0.625rem',
            width: '100%',
            background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${Colors.shadowWhite} 100%)`
          }}
        />

        <Box
          sx={{
            width: '100%',
            justifyContent: 'flex-start',
            zIndex: 200,
            paddingTop: '0.5rem',
            paddingBottom: '0.825rem',
            paddingX: isLargeScreen ? '0rem' : '1rem',
            overflowX: 'auto',
            overflowY: 'hidden',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            '&::-webkit-scrollbar-thumb': {
              display: 'none'
            },
            whiteSpace: 'nowrap'
          }}>
          {Object.entries(incomingSuggestions).map(([key, value]) =>
            value ? (
              <Box
                sx={{
                  animation: `${pulseEffect} 1s infinite`,
                  borderRadius: '3rem',
                  height: '2.375rem',
                  paddingY: '1rem',
                  width: '10.375rem',
                  paddingX: '1rem',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  marginRight: '0.5rem'
                }}
                key={key}></Box>
            ) : null
          )}
        </Box>
      </Box>
    );
  }

  if (!suggestedActions) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '-0.5rem'
      }}>
      <Box
        sx={{
          height: '0.625rem',
          width: '100%',
          background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${Colors.shadowWhite} 100%)`
        }}
      />

      <Box
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          zIndex: 200,
          paddingTop: '0.5rem',
          paddingBottom: '0.825rem',
          paddingX: isLargeScreen ? '0rem' : '1rem',
          overflowX: 'auto',
          overflowY: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '&::-webkit-scrollbar-thumb': {
            display: 'none'
          },
          whiteSpace: 'nowrap'
        }}>
        {suggestedActions.map((action) => (
          <Button
            variant="outlined"
            key={action.action}
            disabled={
              loadingState.affirmation ||
              loadingState.meditation ||
              loadingState.visualization
            }
            onClick={() => handleSuggestionClick(action.parameters)}
            sx={{
              borderRadius: '3rem',
              height: '2.375rem',
              paddingY: '1rem',
              fontSize: '1rem',
              fontFamily: 'Nunito',
              fontWeight: 600,
              border: `1px solid ${Colors.primary.main}`,
              backgroundColor: `${alpha(Colors.green.light, 0.3)}`,
              paddingX: '1rem',
              whiteSpace: 'nowrap',
              marginRight: '0.5rem',
              '&:hover': {
                backgroundColor: `${alpha(Colors.green.light, 0.1)}`
              },
              '&:disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'all !important',
                backgroundColor: loadingState[
                  action.action as keyof typeof loadingState
                ]
                  ? `${alpha(Colors.green.light, 0.3)}`
                  : `${alpha(Colors.greyWhite, 0.4)}`,
                ...(loadingState[
                  action.action as keyof typeof loadingState
                ] && {
                  color: Colors.primary.main,
                  borderColor: Colors.primary.main
                })
              }
            }}>
            {loadingState[action.action as keyof typeof loadingState] ? (
              <>
                Creating {action.action}{' '}
                <CircularProgress
                  size={20}
                  sx={{ color: Colors.primary.main, marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              `Create ${action.action}`
            )}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default SuggestedAction;
