import { CustomAffirmationFlow } from 'modules/new-affirmation/types/initialState';

import { CreateCustomAffirmationDto } from 'redux/affirmation/types/affirmation.entity';

export const transformCustomAffirmation = (
  developmentArea: string,
  customAffirmationFlow: CustomAffirmationFlow
): CreateCustomAffirmationDto => {
  return {
    developmentArea: developmentArea,
    outcome: customAffirmationFlow.outcome,
    qualities: customAffirmationFlow.quality.map((q) => ({
      quality: q.quality,
      traits: q.traits.map((t) => ({ trait: t }))
    }))
  };
};
